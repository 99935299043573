<template>
  <div ref="container" class="signContainer">
    <div class="title">活动管理</div>
    <div class="tableContainer">
      <el-button class="add" @click="addSign">新增活动</el-button>
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
            @row-click="handleEdit"
          >
            <el-table-column label="ID" width="90" prop="sort" align="center" />

            <el-table-column
              label="活动标题"
              min-width="270"
              prop="title"
              align="center"
            />

            <el-table-column
              label="发布时间"
              min-width="190"
              prop="createTime"
            />
            <el-table-column label="状态" min-width="140" align="center">
              <template slot-scope="scope">
                <span
                  :class="scope.row.status === 0 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleRun(scope.$index, scope.row)"
                  >启用</span
                >
                <span
                  :class="scope.row.status === 1 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleRun(scope.$index, scope.row)"
                  >停用</span
                >
              </template>
            </el-table-column>
            <el-table-column label="置顶" min-width="140" align="center">
              <template slot-scope="scope">
                <span
                  :class="scope.row.topFlag > 0 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleTop(scope.$index, scope.row)"
                  >置顶</span
                >
                <span
                  :class="scope.row.topFlag === 0 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleTop(scope.$index, scope.row)"
                  >不置顶</span
                >
              </template>
            </el-table-column>
            <el-table-column label="管理操作" min-width="360" align="center">
              <template slot-scope="scope">
                <span class="editCommon" @click.stop="handleEdit(scope.row)"
                  >编辑</span
                >
                <span class="editCommon" @click.stop="handleSignEdit(scope.row)"
                  >管理报名</span
                >
                <span
                  class="deleteCommon"
                  @click.stop="handleDelete(scope.$index, scope.row)"
                  >删除</span
                >
                <span
                  class="deleteCommon"
                  @click.stop="handleActivity(scope.$index, scope.row)"
                  >生成报名码</span
                >
                <span
                  class="deleteCommon"
                  @click.stop="handleDown(scope.$index, scope.row)"
                  >生成签到码</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="signListDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/utils/eventBus";
import {
  getActivitiesList,
  deleteActivity,
  setActivityTop,
  downActivityImg,
} from "@/apis/user";
import { parseTime } from "@/utils/index";
export default {
  data() {
    return {
      tableHeight: "",
      signListDetail: "",
      tableData: [],
      current: 1,
    };
  },
  activated() {
    EventBus.$on("needRefrush", () => {
      this.current = 1;
    });
    this.getActivitiesList(this.current);
  },
  methods: {
    getActivitiesList(current = 1) {
      getActivitiesList({ current }).then((res) => {
        var records = res.result.records;
        records.forEach((item) => {
          item.createTime = parseTime(item.createTime);
        });
        this.tableData = records;
        this.signListDetail = res.result;
      });
    },
    handleRun(index, row) {
      if (row.status === 0) {
        row.status = 1;
      } else {
        row.status = 0;
      }
      deleteActivity({
        newsId: row.newsId,
        status: row.status,
      }).then(() => {
        if (row.status === 0) {
          this.$message1("该条活动已启用", "success");
        } else {
          this.$message1("该条活动已停用", "success");
        }
      });
    },
    handleTop(index, row) {
      if (row.topFlag > 0) {
        row.topFlag = 0;
      } else {
        row.topFlag = 1;
      }
      const obj = {
        newsId: row.newsId,
        topFlag: row.topFlag,
      };

      setActivityTop(obj).then(() => {
        if (row.topFlag === 1) {
          this.$message1("该条活动已置", "success");
        } else {
          this.$message1("该条活动取消置顶", "success");
        }
      });
    },
    handleDown(index, row) {
      const that = this;
      var qrData = {
        page: "package/signin/register/register",
        scene: row.newsId,
        width: 280,
      };
      downActivityImg(qrData).then((res) => {
        that.picture = res.result.image;
        var x = new XMLHttpRequest();
        x.open("GET", res.result.image.split("?")[0], true);
        x.responseType = "blob";
        x.onload = function () {
          var url = window.URL.createObjectURL(x.response);
          var a = document.createElement("a");
          a.href = url;
          a.download = row.title + "活动签到太阳码";
          a.click();
        };
        x.send();
      });
    },
    handleActivity(index, row) {
      const that = this;
      var qrData = {
        page: "package/common/newsDetail/newsDetail",
        scene: row.newsId,
        width: 280,
      };
      downActivityImg(qrData).then((res) => {
        that.picture = res.result.image;
        var x = new XMLHttpRequest();
        x.open("GET", res.result.image.split("?")[0], true);
        x.responseType = "blob";
        x.onload = function () {
          var url = window.URL.createObjectURL(x.response);
          var a = document.createElement("a");
          a.href = url;
          a.download = row.title + "活动报名太阳码";
          a.click();
        };
        x.send();
      });
    },

    handleEdit(row) {
      this.$router.push({
        name: "SignEdit",
        params: {
          newsId: row.newsId,
        },
      });
    },
    handleSignEdit(row) {
      this.$router.push({
        name: "SignManage",
        params: {
          newsId: row.newsId,
        },
      });
    },
    handleDelete(index, row) {
      this.$handleConfirm("请确认是否要删除这条活动?")
        .then(() => {
          deleteActivity({
            newsId: row.newsId,
            status: -1,
          }).then(() => {
            this.tableData.splice(index, 1);
            this.$message1("该条新闻已活动", "success");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addSign() {
      this.$router.push({
        name: "SignAdd",
      });
    },
    clickPage(e) {
      this.getActivitiesList(e);
      this.current = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.signContainer {
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 30px;
  }
  .tableContainer {
    overflow: hidden;
    border: solid 1px #d2d2d2;
    margin: 40px 34px 0;
    box-sizing: border-box;
    .add {
      float: none;
      margin: 12px 0 0 14px;
      @include btnCardConfirm(100px, 34px, 14px);
    }
    .table {
      margin-top: 12px;
    }
  }
  .pagination {
    margin-top: 24px;
  }
}
</style>
